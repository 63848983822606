// store.js or wherever the store and clearPersistedData are defined
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { apiSlice } from "./Reducers/apiReducer";
import cartReducer from "./Reducers/cartReducer";
import productReducer from "./Reducers/productReducer";
import orderReducer from "./Reducers/orderReducer";
import authReducer from "./Reducers/authReducer";
import addressReducer from "./Reducers/addressReducer";
const persistConfig = {
  key: "root",
  storage,
};

const persistedCartReducer = persistReducer({ ...persistConfig, key: "cart" }, cartReducer);
const persistedProductReducer = persistReducer({ ...persistConfig, key: "product" }, productReducer);
const persistedOrderReducer = persistReducer({ ...persistConfig, key: "order" }, orderReducer);

const store = configureStore({
  reducer: {
    cart: persistedCartReducer,
    product: persistedProductReducer,
    api: apiSlice.reducer,
    order: persistedOrderReducer,
    auth: authReducer,
    address:addressReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(apiSlice.middleware),
});

const persistor = persistStore(store);

const clearPersistedData = () => {
  storage.removeItem("persist:cart");
  storage.removeItem("persist:product");
  storage.removeItem("persist:order");
  // console.log("Specific persisted data cleared");
};

setTimeout(clearPersistedData, 180000);

export { store, persistor, clearPersistedData };
