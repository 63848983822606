import React from "react";
import { ReactSVG } from "react-svg";
import Logo from "../../Assets/svgs/LaundraMoon.svg";

const styleComponent = {
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const FallbackLoader = () => {
  return (
    <>
      <div style={styleComponent} className="fallback-animation">
        <div className="react-svg-wrapper">
          <img style={{ width: "240px", height: "240px", objectFit: "contain" }} src={require("../../Assets/img/logo.png")} />
        </div>
      </div>
    </>
  );
};

export default FallbackLoader;
