import { createSlice } from "@reduxjs/toolkit";

export const addressSlice = createSlice({
  name: "address",
  initialState: {
    addresses: [], // Array to store multiple addresses
    currentAddress: {
      id: null,
      postcode: null,
      lat: null,
      lng: null,
      city: null,
      state: null,
      address: null,
      country: null,
      FlatApartmentNo: null,
      HotelApartmentNo: null,
      addressType: "Home",
    },
  },
  reducers: {
    addAddress: (state, action) => {
      state.addresses.push(action.payload);
    },
    updateAddress: (state, action) => {
      const index = state.addresses.findIndex(address => address.id === action.payload.id);
      if (index !== -1) {
        state.addresses[index] = action.payload;
      }
    },
    deleteAddress: (state, action) => {
      state.addresses = state.addresses.filter(address => address.id !== action.payload);
    },
    setCurrentAddress: (state, action) => {
      state.currentAddress = action.payload;
    },
    clearCurrentAddress: (state) => {
      state.currentAddress = {
        id: null,
        postcode: null,
        lat: null,
        lng: null,
        city: null,
        state: null,
        address: null,
        country: null,
        FlatApartmentNo: null,
        HotelApartmentNo: null,
        addressType: "Home",
      };
    },
  },
});

export const { addAddress, updateAddress, deleteAddress, setCurrentAddress, clearCurrentAddress } = addressSlice.actions;

export default addressSlice.reducer;
