// authSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: null,
  userInfo: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    resetAuthState: (state) => {
      // Reset the state to its initial values
      state.token = initialState.token;
      state.userInfo = initialState.userInfo;
    },
  },
});

export const { setToken, setUserInfo, resetAuthState } = authSlice.actions;

export default authSlice.reducer;
