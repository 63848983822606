import React from "react";
import { createRoot, hydrateRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store, persistor } from "./Store/store.js";
import { PersistGate } from "redux-persist/integration/react";
import { HelmetProvider } from 'react-helmet-async';
import SEO from "./Shared/SEO/SEO.jsx";


const rootElement = document.getElementById('root');

// Check if React Snap is pre-rendering
const isSnap = navigator.userAgent === 'ReactSnap';

if (rootElement && rootElement.hasChildNodes() && isSnap) {
  // Use hydrateRoot for React Snap pre-rendered pages
  hydrateRoot(
    rootElement,
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HelmetProvider>
          <SEO />
          <App />
        </HelmetProvider>
      </PersistGate>
    </Provider>
  );
} else {
  // Use createRoot for normal rendering
  const root = createRoot(rootElement);
  root.render(
    <Provider store={store}>
      <React.StrictMode>
        <PersistGate loading={null} persistor={persistor}>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </PersistGate>
      </React.StrictMode>
    </Provider>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
